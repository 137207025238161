<!--
  哈希 MD5 组件
-->
<template>
	<div class="md5-tools-page">
		<md5-header />
		<main class="md5-tools-box">
			<div class="hash-component">
				<a
					href="javascript:history.back(-1)"
					rel="noopener noreferrer"
					class="goback"
					:title="$t('lang.gobackBack')"
				>
					<i class="el-icon-back"></i>
					{{ $t('lang.goback') }}
				</a>
				<h3 class="title">{{ h3 }}</h3>
				<el-row class="z-center">
					<el-col :span="24">
						<el-tabs v-model="type" type="border-card" @tab-click="handleClick">
							<el-tab-pane
								:label="item.name"
								:name="item.type"
								:key="index"
								v-for="(item, index) in cryptList"
							>
								<el-row>
									<el-col :span="24" class="col">
										<el-input
											type="textarea"
											v-model.trim="value"
											rows="12"
											minlength="5"
											:placeholder="$t('lang.websocket.encryptionInput')"
										></el-input>
									</el-col>
									<el-col :span="24" class="col content-button">
										<div class="submit" @click="copyResult">
											<a href="javascript:void(0)">{{
												$t('lang.copyResult')
											}}</a>
										</div>
										<div class="submit" @click="cleanInput">
											<a href="javascript:void(0)">{{ $t('lang.clear') }}</a>
										</div>
									</el-col>
									<el-col :span="24" class="col result-textarea">
										<el-input
											type="textarea"
											v-model.trim="result"
											rows="12"
											:placeholder="$t('lang.websocket.encryptionRes')"
											readonly
										></el-input>
									</el-col>
								</el-row>
							</el-tab-pane>
						</el-tabs>
					</el-col>
				</el-row>
			</div>
		</main>
		<md5-footer />
	</div>
</template>

<script>
import md5Header from './../../components/Header';
import md5Footer from './../../components/Footer';
import CryptoJS from 'crypto-js';
import { get_tools_detail } from '@/api/tools';

let timer;
export default {
	name: 'hash',
	components: {
		md5Header,
		md5Footer,
	},
	data() {
		return {
			h3: '',
			cryptList: [
				{ name: 'MD5', type: 'MD5' },
				{ name: 'Sha1', type: 'SHA1' },
				{ name: 'Sha256', type: 'SHA256' },
				{ name: 'Sha384', type: 'SHA384' },
				{ name: 'Sha512', type: 'SHA512' },
				{ name: 'Sha3_512', type: 'SHA3_512' },
				{ name: 'Sha3_384', type: 'SHA3_384' },
				{ name: 'Sha3_224', type: 'SHA3_224' },
				{ name: this.$t('lang.api.cryptList_1'), type: 'BASE64_ENCODE' },
				{ name: this.$t('lang.api.cryptList_2'), type: 'BASE64_DECODE' },
			],
			type: 'MD5',
			value: '',
			result: '',
		};
	},
	created() {
		this.getToolsDetail();
	},
	methods: {
		getToolsDetail() {
			get_tools_detail({ id: this.$route.query.id }).then((res) => {
				if (res) {
					this.h3 = res.name;
				}
			});
		},
		debounce: function () {
			if (timer) {
				clearTimeout(timer);
			}
			timer = setTimeout(() => {
				this.$message({
					message: this.$t('lang.api.message'),
					type: 'error',
				});
				timer = undefined;
			}, 1000);
		},
		handleClick() {
			this.encrypt();
		},
		copyResult() {
			this.copyData(this.result);
			document.querySelector('.result-textarea .el-textarea__inner').select(); //复制 获得焦点
		},
		cleanInput: function () {
			this.value = '';
		},
		encrypt() {
			this.result = '';
			const str_array = this.value.split(/[(\r\n)\r\n]+/);
			str_array.map((item) => {
				if (item !== '') {
					switch (this.type) {
						case 'MD5':
							this.result += CryptoJS.MD5(item).toString() + '\n';
							break;
						case 'SHA1':
							this.result += CryptoJS.SHA1(item).toString() + '\n';
							break;
						case 'SHA256':
							this.result += CryptoJS.SHA256(item).toString() + '\n';
							break;
						case 'SHA512':
							this.result += CryptoJS.SHA512(item).toString() + '\n';
							break;
						case 'SHA384':
							this.result += CryptoJS.SHA384(item).toString() + '\n';
							break;
						case 'SHA3_512':
							this.result +=
								CryptoJS.SHA3(item, { outputLength: 512 }).toString() + '\n';
							break;
						case 'SHA3_384':
							this.result +=
								CryptoJS.SHA3(item, { outputLength: 384 }).toString() + '\n';
							break;
						case 'SHA3_224':
							this.result +=
								CryptoJS.SHA3(item, { outputLength: 224 }).toString() + '\n';
							break;
						case 'SHA3_256':
							this.result +=
								CryptoJS.SHA3(item, { outputLength: 256 }).toString() + '\n';
							break;
						case 'BASE64_ENCODE':
							var wordArray = CryptoJS.enc.Utf8.parse(item);
							var base64 = CryptoJS.enc.Base64.stringify(wordArray);
							this.result += base64;
							break;
						case 'BASE64_DECODE':
							try {
								var parsedWordArray = CryptoJS.enc.Base64.parse(item);
								var parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
								this.result += parsedStr;
							} catch (error) {
								console.log(error);
								this.debounce();
							}

							break;
						default:
							this.result = '';
					}
				}
			}, this);
		},
	},
	watch: {
		value: 'encrypt',
	},
};
</script>

<style lang="scss" scoped>
.md5-tools-page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: url(../../assets/img/cmd-index-bg.png) no-repeat center;
	background-size: cover;
	background-attachment: fixed;
	.md5-tools-box {
		flex: 1;
		padding-top: 30px;
		width: 1208px;
		margin: 0 auto;
	}
	.title {
		width: 100%;
		height: 60px;
		font-size: 30px;
		font-weight: bold;
		color: #333333;
		line-height: 36px;
		text-align: center;
	}
	.goback {
		float: right;
		font-size: 16px;
		font-weight: 400;
		color: rgba(68, 169, 254, 1);
		cursor: pointer;
		display: flex;
		align-items: center;
		i {
			font-size: 18px;
		}
		&:hover {
			color: #37bef0;
		}
	}
	.hash-component {
		width: 100%;
		margin-top: 10px;
		height: auto;
		background: #ffffff;
		box-shadow: 0px 0px 20px 0px rgba(68, 169, 254, 0.15);
		padding: 35px;
		border-radius: 6px;
		.col {
			margin-top: 20px;
		}
		.content-button {
			display: flex;
			justify-content: center;
			.submit {
				width: 80px;
				height: 36px;
				border-radius: 6px;
				text-align: center;
				padding-top: 9px;
				background: linear-gradient(
					0deg,
					rgba(255, 255, 255, 1) 0%,
					rgba(240, 248, 255, 1) 100%
				);
				border: 1px solid rgba(255, 255, 255, 1);
				margin-left: 24px;
				box-shadow: 0px 0px 10px 0px rgba(79, 94, 106, 0.15);
			}
			.submit a {
				color: rgba(22, 148, 250, 1);
			}
		}
		/deep/ .el-tabs--border-card {
			border-radius: 6px;
			box-shadow: none;
			.el-tabs__content {
				padding-top: 0px;
			}
			// AES 加密/解密
			.el-tabs__header {
				border-bottom: 0px;
				border-radius: 6px 6px 0 0;
				background: rgba(229, 229, 229, 0.3);
				.el-tabs__item.is-active {
					color: rgba(56, 56, 56, 1);
					background-color: #fff;
					border-right-color: #fff;
					border-left-color: #fff;
					border-radius: 10px 6px 0 0;
				}
				.el-tabs__item:not(.is-disabled):hover {
					color: rgba(56, 56, 56, 1);
				}
			}
		}
		/deep/ .el-textarea__inner {
			resize: none;
			min-height: 33.2222px;
			padding-top: 19px;
			padding-left: 25px;
			border-radius: 6px;
			color: rgba(128, 128, 128, 1);
			font-family: '';
		}
	}
}

@keyframes pulse {
	0% {
		transform: scaleX(1);
	}

	50% {
		transform: scale3d(1.03, 1.03, 1.03);
	}

	to {
		transform: scaleX(1);
	}
}
</style>
